<template>
    <div :height="cardHeight" class="policy-finder">
        <p class="q-display-3 pl-5 text--q_blue_1">{{title}}</p>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details></v-text-field>
        <v-data-table :search="search" :footer-props.sync="footerOptions" :options.sync="options" :items-per-page.sync="rows" show-expand :expanded.sync="expanded" :mobile-breakpoint="2" :headers="the_headers" :items="results" class="elevation-1 leaderboard-datatable" single-expand>
            <template v-slot:item.age="{ item }">
                <span class="nowrap"><strong>{{ item.min_age }} - {{ item.max_age }}</strong></span>
            </template>
            <template v-slot:item.under_writing="{ item }">
                <span class="nowrap"><a :href="'tel:' + item.under_writing">{{item.under_writing}}</a></span>
            </template>
            <template v-slot:item.client_phi="{ item }">
                <span class="nowrap"><a :href="'tel:' + item.client_phi">{{item.client_phi}}</a></span>
            </template>
            <template v-slot:item.email="{ item }">
                <span class="nowrap"><a :href="'mailto:' + item.email">{{item.email}}</a></span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" wrap>
                    <v-row style="position:relative;">
                        <a :id="'insurance_id_' + item.insurance_id" style="position:absolute; top:-135px;opacity: 0">None</a>
                        <v-col cols="12" sm="2">
                            <v-btn block text outlined color="q_new_b_1" class="d-flex align-center px-4 ma-1 q_new_b-1" height="40" @click="overlay = !overlay">Build Chart</v-btn>
                        </v-col>
                        <v-col cols="12" sm="2" v-if="item.agent_guide">
                            <v-btn block text outlined color="q_new_b_1" class="d-flex align-center px-4 ma-1 q_new_b-1" height="40" @click="loadGuide(item.agent_guide)">Product Guide</v-btn>
                        </v-col>
                        <v-col cols="12" sm="2" v-if="item.product_video">
                            <v-btn block text outlined color="q_new_b_1" class="d-flex align-center px-4 ma-1 q_new_b-1" height="40" @click="loadUrl(item.product_video)">Video</v-btn>
                        </v-col>
                        <v-col cols="12" sm="2" v-if="item.run_quote">
                            <v-btn block text outlined color="q_new_b_1" class="d-flex align-center px-4 ma-1 q_new_b-1" height="40" @click="loadUrl(item.run_quote)">Run Quote</v-btn>
                        </v-col>
                        <v-col cols="12" sm="2" v-if="item.application">
                            <v-btn block text outlined color="q_new_b_1" class="d-flex align-center px-4 ma-1 q_new_b-1" height="40" @click="loadUrl(item.application)">Application</v-btn>
                        </v-col>
                        <v-col cols="12">
                            <b>Description: </b><span v-html="item.description"></span>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <b>Address:</b>
                            {{ item.Address1 }}<br>
                            <span v-if="item.Address2">{{ item.Address2 }}<br></span>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <b>Company Link: </b>
                            <a :href="item.company_link">{{ item.company_link }}</a>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <b>Insurance Name: </b>{{ item.insurance_name }}
                        </v-col>
                        <v-col cols="12" sm="4">
                            <b>ID: </b>{{ item.id }}
                        </v-col>
                        <v-col cols="12" sm="4">
                            <b>Insurance C ID: </b>{{ item.insurance_c_id }}
                        </v-col>
                        <v-col cols="12" sm="4">
                            <b>Insurance Code: </b>{{ item.insurance_code }}
                        </v-col>
                        <v-col cols="12" sm="4">
                            <b>Insurance ID: </b>{{ item.insurance_id }}
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
        <v-dialog width=" 500" v-model="overlay">
            <v-card class="pt-1 pb-4 pt-5 px-7 white">
                <div class="text-center q-display-2">
                    Build Chart - SAMPLE - do not use!!!
                </div>
                <div class="my-5">
                    <buildchart></buildchart>
                </div>
                <div class="text-center">
                    <v-btn dark color="q_new_b_1" @click="overlay = false">
                        <v-icon small left>fas fa-times</v-icon>Close
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import Buildchart from '../../views/NewBusiness/ProdLinks/Buildchart.vue';


export default {

    name: "QPolicyFinderResultsTable",
    props: ['results', 'rows', 'title'],
    data: function() { // data internal to component (not available outside)
        return {
            search: null,
            expanded: [],
            overlay: false,
            footerOptions: { itemsPerPageOptions: [this.rows, 5, 10] }
        }
    },

    mounted: function() {},
    components: {
        Buildchart,
    },

    computed: {
        the_headers: function() {
            return [{
                text: 'Company',
                value: 'company_name',
                sortable: true,
            }, {
                text: 'Policy Type',
                value: 'insurance_name',
                sortable: true,
            }, {
                text: 'Issue Ages',
                value: 'age',
                sortable: true,
            }, {
                text: 'Underwriting',
                value: 'under_writing',
                sortable: true,
            }, {
                text: 'Client PHI',
                value: 'client_phi',
                sortable: true,
            }, {
                text: 'Fax',
                value: 'fax_no',
                sortable: true,
            }, {
                text: 'Email',
                value: 'email',
                sortable: true,
            }, {
                text: 'E & O',
                value: 'require_eo',
                sortable: true,
            }]
        },
        cardHeight: function() {
            if (this.$vuetify.breakpoint.xs) {
                return null
            }
            return 257 + (this.rows * 56) + "px"
        },
    },
    methods: {
        loadGuide: function(guide) {
            var win = window.open('https://sfgvirtualmentor.com/Public/uploads/document/' + guide, '_blank');
            win.focus();
        },
        loadUrl: function(url) {
            var win = window.open(url, '_blank');
            win.focus();
        }
    },
    watch: {
        'expanded': function(newV) {
            console.log(newV[0])
            var g = this
            this.$nextTick(function() {
                g.$vuetify.goTo("#insurance_id_" + newV[0].insurance_id)
            })
        }
    },


}

</script>
<style>
.policy-finder .v-data-table__expand-icon.v-icon.v-icon {
    font-size: 1em;
}

</style>
