<template>
    <div>
        <v-toolbar class="mb-5 q-sticky" style="height:80px; padding-top:10px;">
            <div class="title-bar-block">
                <p class="q-title-bar-title ">New Business</p>
                <p class="q-title-bar-link"><a href="https://sfgvirtualmentor.com/ipq/ipq_page" target="_blank">open virtual mentor</a></p>
            </div>
            <!--
            <v-btn text :to="'/new_business/workflow/' + lead_id + '/needs-analysis/'" class="mr-2 ml-2 ">
                Needs Analysis
                <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
            </v-btn>
            <v-btn text :to="'/new_business/workflow/' + lead_id + '/policy-finder/'" class="mr-7 ml-2 ">
                Policy Finder
                <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
            </v-btn>
            <v-menu offset-y v-model="show_search_results">
                <template v-slot:activator="{ on }">
                    <v-text-field v-model="search_leads" label="Find Lead" dense single-line hide-details append-icon="mdi-magnify"></v-text-field>
                </template>
                <v-list>
                    <v-list-item v-for="(result, key) in search_results" :key="key" @click="loadLead(result)">
                        {{result.Applicant}} {{result.Address}}
                    </v-list-item>
                </v-list>
            </v-menu>
        -->
            <v-spacer></v-spacer>
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>fas fa-ellipsis-v</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <menu-item icon="fas fa-external-link-alt" href="/support">Help</menu-item>
                    <!--
                    <menu-item icon="fas fa-scroll" :click="showScript">Suggested Script</menu-item>
                    
                        <menu-item icon="fas fa-external-link-alt" href="https://federate.ipipeline.com/">iPipeline</menu-item>
                    -->
                </v-list>
            </v-menu>
        </v-toolbar>
        <!-- 
            Shows the "child-view" passed in the URL 
            this is so we can pass a user to the apps etc
            -->
        <keep-alive>
            <router-view v-on:lead="onLead"></router-view>
        </keep-alive>
        <v-bottom-sheet v-model="sheet">
            <v-sheet class="text-left px-5 pt-1" height="20%">
                <v-col cols="12" class="pl-5">
                    <v-btn class="mt-6" fab right top text dark color="orange accent-4" @click="sheet = !sheet">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                </v-col>
                <v-row class="d-flex justify-start px-5">
                    <v-col cols="12" sm="4">
                        <div>
                            <p><strong>Before the sale</strong> <em>(goes between Step 3 & 4 of the R.E.A.L. script)</em></p>
                            <p>Before we talk about options, I never want to recommend more or less coverage than you might need, so I'm going to ask you a few questions to determine how much coverage we should be looking at. Does that make sense?</p>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <p><strong>Post-Sale</strong></p>
                        <p>Based on some of the things we discussed, I have a couple of additional questions.</p>
                        <ol>
                            <li>What is the purpose of the money you’re saving in your ___________ (401(k), IRA, etc.)?</li>
                            <li>You have car insurance, homeowners insurance, life insurance, and health insurance. Is your money protected from market declines?</li>
                            <li>Do you have a guaranteed lifetime income plan for retirement?</li>
                        </ol>
                        <p>Okay, let's do this. The last thing I want to happen is another financial crisis like in 2008, finding out that you lost half of your retirement savings when you didn't have to lose anything. I work with a team of specialists that will thoroughly review your unique situation and provide us with recommendations on how to protect your investments – and none of this will cost you a dime. Let’s get a few more questions answered. From there, I'll send the results to the team and I’ll schedule a time for us to sit down and review what they find.</p>
                        <p>Fill out the questionnaire and schedule a time to come back out next week.</p>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>
<script>
import MenuItem from '@/components/navigation/MenuItem.vue'
import Lead from '@/store/Models/Lead'
//table data + search
export default {
    data() {
        return {
            tab: null,
            lead_id: this.$route.params.lead_id,
            sheet: false,
            search_leads: null,
            show_search_results: false,
        }
    },

    mounted: function() {
        this.tab = '/new_business/workflow/needs-analysis/' + this.lead_id;
    },
    computed: {
        search_results: function() {
            var g = this
            if (this.search_leads == null || this.search_leads.length <= 2) { return [] }
            var leads = Lead.query().where("Applicant", function(l) {
                return l.toLowerCase().indexOf(g.search_leads.toLowerCase()) > -1;
            });
            return leads.get();
        }
    },
    methods: {
        onLead: function(id) {
            this.lead_id = id
        },
        showScript: function() {
            console.log("test")
            this.sheet = true;
        },
        loadLead: function(lead) {
            //swap out old lead id with the new lead id. 
            var current_id = this.$router.currentRoute.params.lead_id;
            var current_route = this.$router.currentRoute.path;
            if (typeof current_id != 'undefined') {
                var new_route = current_route.replace(current_id, lead.$id)
            } else {
                new_route = current_route.replace('/workflow/', '/workflow/' + lead.$id + "/")
            }
            this.search_leads = lead.Applicant;
            var g = this
            this.$nextTick(function() {
                g.show_search_results = false
            })
            if (new_route != current_route) this.$router.push(new_route)
        }
    },
    watch: {
        search_leads: function(newV) {
            if (newV.length >= 3) {
                this.show_search_results = true
            }
            if (newV.length <= 2) {
                this.show_search_results = false
            }
        }
    },
    components: {
        MenuItem
    }
}

</script>
