<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-clients-submenu></q-my-clients-submenu>
        <q-agent-commission-datatable :agent="user.Agent" title="PCS Bonus"></q-agent-commission-datatable>
    </v-container>
</template>
<script>
//table data + search
import QAgentCommissionDatatable from '@/components/datatables/QAgentCommissionDatatable.vue'
import QMyClientsSubmenu from '@/components/navigation/Submenus/QMyClientsSubmenu.vue';
export default {
    data() {
        return {
            search: '',
        }
    },
    mounted: function() {
        // console.log(this.user)
    },
    computed: {},
    methods: {
        'startDemo': function() {
            this.$tours['DemoPendingPolicy'].start()
        },
    },


    components: {
        QAgentCommissionDatatable,
        QMyClientsSubmenu
    },
}
</script>