<template>
    <div>
        <q-my-clients-submenu></q-my-clients-submenu>
        <v-row class="mx-5">
            <v-col cols=12>
                <v-card class="pb-2">
                    <q-placed-policy-data-table :agent="user.Agent" :rows="25" title="Placed Policies"></q-placed-policy-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
//table data + search
import QPlacedPolicyDataTable from '@/components/datatables/QPlacedPolicyDataTable.vue';
import QMyClientsSubmenu from '@/components/navigation/Submenus/QMyClientsSubmenu.vue';
import QTabs from '../../components/navigation/QTabs.vue'


export default {
    data() {
        return {
            search: '',
        }
    },
    mounted: function() {},
    computed: {},
    methods: {
        // 'startDemo': function() {
        //     this.$tours['DemoPendingPolicy'].start()
        // },
    },


    components: {
        // QPendingPolicyDataTable,
        QMyClientsSubmenu,
        QPlacedPolicyDataTable,
        QTabs
    },
}
</script>