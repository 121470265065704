<template>
    <v-col cols="4">
        <v-menu v-model="show_results" offset-y bottom class="quick-results">
            <template v-slot:activator="{ on }">
                <div>
                    <v-text-field append-outer-icon="fas fa-search" label="Search Leads" v-model="keywords" v-on:keyup="performSearch" filled color="white" dark @click:append-outer="performSearch"></v-text-field>
                </div>
            </template>
            <v-list dense>
                <v-list-item v-if="loading" class="text-center">
                    <v-list-item-content>
                        <v-progress-circular indeterminate></v-progress-circular>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-else-if="results.length == 0">
                    <v-list-item-content>
                        No results
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-else @click="loadResult(result)" v-for="(result, k) in results" :key="k">
                    <v-list-item-content>
                        <v-list-item-title class="q_blue_1--text"><strong>{{ result.Contact }}</strong>({{ result.Email }})</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            </div>
        </v-menu>
    </v-col>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import NeedsAnalysisLead from '@/store/Models/NeedsAnalysisLead.js'
export default {
    props: ['model', 'titleProp', 'baseUrl'],
    data: function() {
        return {
            keywords: null,
            show_results: false
        }
    },
    computed: {
        'loading': function() {
            return this.$store.state.entities.needs_analysis_lead.loading
        },
        'results': function() {
            return NeedsAnalysisLead.all();
        },
    },
    methods: {
        loadResult: function(result) {
            if (typeof this.baseUrl != 'undefined') {
                var url = this.baseUrl.replace('::LEADID::', result.LeadID);
            } else {
                url = '/new_business/workflow/' + result.LeadID + "/needs-analysis"
            }
            this.$router.push(url)
        },
        loadData: debounce(function() {
            var g = this
            g.show_results = true
            QuilityAPI.getMyNeedsAnalysisLeads({ search: this.keywords, itemsPerPage: 8, page: 1 }).then(function() {});
            this.firstPull = false;
        }, 500),
        performSearch: function(e) {
            if (e.keyCode === 13) {
                this.loadData();
            }
            if (this.keywords == null || this.keywords == '') {
                this.show_results = false
            } else {
                this.loadData();
            }
        },
    },
    watch: {
        'keywords': function(newV) {
            //this.show_results = false
        }
    },
    components: {}
}

</script>
